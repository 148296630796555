import React, { useState } from 'react';
import { cloneElement } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/images/svg/ch-right.svg';
import {
  Box,
  MenuItem,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from 'ui-component/Logo';
import SecondaryButton from 'ui-component/button/SecondaryButton';

const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  ...theme.typography.h4,

  ':hover': {
    borderRadius: theme.spacing(0.5),
  },

  [theme.breakpoints.down('sm')]: {
    ...theme.typography.h6,
  },
}));

const HeaderStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  gap: theme.spacing(5),

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}));

function ElevationScroll({ children, window }) {
  const theme = useTheme();

  return cloneElement(children, {
    style: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.dark,
    },
  });
}

const Header = ({ ...others }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const scrollToSection = (section) => {
    const headerOffset = 64; // adjust this value based on your header height
    const element = document.getElementById(section);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleHomeClick = () => {
    navigate('/home');
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 100);
  };

  const handleAboutUsClick = () => {
    navigate('/home');
    setTimeout(() => {
      scrollToSection('hero-section');
    }, 100);
  };

  const handleContactUsClick = () => {
    navigate('/home');
    setTimeout(() => {
      scrollToSection('contact-us');
    }, 100);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const menuItems = (
    <List>
      <ListItem button onClick={handleHomeClick}>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button onClick={handleAboutUsClick}>
        <ListItemText primary="About us" />
      </ListItem>
      <ListItem button onClick={handleContactUsClick}>
        <ListItemText primary="Contact us" />
      </ListItem>
    </List>
  );

  return (
    <ElevationScroll {...others}>
      <MuiAppBar sx={{ position: 'sticky', top: 0 }}>
        <Box px={4}>
          <Toolbar
            sx={{
              py: 2,
              px: '0 !important',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative', // Ensures correct positioning of button
            }}
          >
            <Typography
              component={RouterLink}
              to={'/home'}
              onClick={scrollToTop}
            >
              <Logo />
            </Typography>

            {isSmallScreen ? (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                >
                  {menuItems}
                </Drawer>
              </>
            ) : (
              <>
                <HeaderStack>
                  <Box
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    onClick={handleHomeClick}
                  >
                    <MenuItemWrapper>Home</MenuItemWrapper>
                  </Box>

                  <Box
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    onClick={handleAboutUsClick}
                  >
                    <MenuItemWrapper>About us</MenuItemWrapper>
                  </Box>

                  <Box
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                    onClick={handleContactUsClick}
                  >
                    <MenuItemWrapper>Contact us</MenuItemWrapper>
                  </Box>
                </HeaderStack>
                <Box
                  sx={{
                    ml: 'auto',
                    mr: 2,
                  }}
                >
                  <SecondaryButton
                    onClick={() => navigate('/login')}
                    title={'Log in'}
                   
                  />
                </Box>
                <Box
                  sx={{
                    ml: 'auto',
                  }}
                >
                  <SecondaryButton
                    onClick={() => navigate('/tax-calculator')}
                    title={'Calculate Now'}
                    endIcon={<ArrowIcon />}
                  />
                </Box>
              </>
            )}
          </Toolbar>
        </Box>
      </MuiAppBar>
    </ElevationScroll>
  );
};

export default Header;