import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Stack,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";

const ManageCookieModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const CookieBox = styled(Box)(({ theme }) => ({
  maxWidth: "750px",
  backgroundColor: "white",
  borderRadius: "10px",
  padding: "20px",
  textAlign: "center",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const CookieButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: "#000",
  fontWeight: "bold",
  minWidth: 140,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const BackButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: "#000",
  fontWeight: "bold",
  minWidth: 140,
  "&:hover": {
    backgroundColor: theme.palette.grey[400],
  },
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.secondary.main,
  },
  '& .MuiSwitch-switchBase.Mui-disabled': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
    backgroundColor: theme.palette.secondary.main,
  },
}));
export default function CookieConsent({ isOpen, setIsOpen, onConsent , setCookieConsentOpen }) {
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    statistic: false,
    preferences: false,
    marketing: false,
  });

  const handleToggle = (type) => {
    setCookiePreferences((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleAllowAll = () => {
    setCookiePreferences({
      necessary: true,
      statistic: true,
      preferences: true,
      marketing: true,
    });
    onConsent();
  };

  const handleAllowSelection = () => {
    onConsent(cookiePreferences);
    setIsOpen(false);
  };

  return (
    <ManageCookieModal open={isOpen}>
      <CookieBox>
        <Stack gap={2} my={5}>
          <Typography variant="h2" fontWeight="600" color="black">
            Manage Cookies
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#484848", textAlign: "left" }}
          >
            Cookies are small text that can be used by websites to make the user
            experience more efficient. The law states that we may store cookies
            on your device if they are strictly necessary for the operation of
            this site. For all other types of cookies, we need your permission.
            This site uses various types of cookies. Some cookies are placed by
            third-party services that appear on our pages.
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left" }}>
            Your permission applies to the following domains:
            <ul>
              <li>cookieinfo.net</li>
              <li>intranet.cookieinfo.net</li>
            </ul>
          </Typography>
        </Stack>

        {/* Cookie Preferences */}
        <Stack gap={3} my={3}>
          {[
            {
              label: "Necessary",
              description:
                "Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.",
              value: cookiePreferences.necessary,
              disabled: true,
            },
            {
              label: "Statistic",
              description:
                "Statistic cookies help website owners understand how visitors interact with websites by collecting and reporting information anonymously.",
              value: cookiePreferences.statistic,
            },
            {
              label: "Preferences",
              description:
                "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.",
              value: cookiePreferences.preferences,
            },
            {
              label: "Marketing",
              description:
                "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.",
              value: cookiePreferences.marketing,
            },
          ].map((cookie) => (
            <Box
              key={cookie.label}
              sx={{
                display: "flex",
                alignItems: "left",
                justifyContent: "space-between",
                borderBottom: "1px solid #e0e0e0",
                pb: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography variant="h6" fontWeight="bold" textAlign={"left"}>
                  {cookie.label}
                </Typography>
                <Typography variant="body2" color="textSecondary" textAlign={"left"}> 
                  {cookie.description}
                </Typography>
              </Box>
              <CustomSwitch
                checked={cookie.value}
                disabled={cookie.disabled}
                onChange={() => handleToggle(cookie.label.toLowerCase())}
              />
            </Box>
          ))}
        </Stack>

        {/* Action Buttons */}
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"baseline"}
          gap={2}
        >
          <BackButton variant="contained" onClick={() => {
            setIsOpen(false);
            setCookieConsentOpen(true);
          }}>
            Back
          </BackButton>
          <CookieButton variant="contained" onClick={handleAllowSelection}>
            Allow selection
          </CookieButton>
          <CookieButton variant="contained" onClick={handleAllowAll}>
            Allow all cookies
          </CookieButton>
        </Stack>
      </CookieBox>
    </ManageCookieModal>
  );
}
